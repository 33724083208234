export default [
  {
    key: 'increasement',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'region',
    label: 'field.region',
    hideToggle: true,
  },
  {
    key: 'image',
    label: 'field.image',
    type: 'image',
  },
  {
    key: 'name',
    label: 'field.name',
    hideToggle: true,
  },
  {
    key: 'idCard',
    label: 'field.idCard',
    hideToggle: true,
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
    hideToggle: true,
  },
  {
    key: 'department',
    label: 'field.department',
    hideToggle: true,
  },
  {
    key: 'position',
    label: 'field.position',
    hideToggle: true,
  },
  {
    key: 'manager',
    label: 'field.manager',
  },
  {
    key: 'workingSchedule',
    label: 'field.workingSchedule',
  },
  {
    key: 'dailySalesTargetAmount',
    label: 'field.dailySalesTargetAmount',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'weeklySalesTargetAmount',
    label: 'field.weeklySalesTargetAmount',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'monthlySalesTargetAmount',
    label: 'field.monthlySalesTargetAmount',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'yearlySalesTargetAmount',
    label: 'field.yearlySalesTargetAmount',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'dpVisible',
    label: 'field.dpVisible',
    thClass: 'text-center',
    tdClass: 'text-center',
    type: 'toggle',
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-center',
    tdClass: 'text-center',
    type: 'toggle',
    hideToggle: true,
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'id',
    label: 'field.createdDate',
    hideToggle: true,
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updatedDate',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
