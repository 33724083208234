export default [
  {
    key: 'name',
    label: 'field.name',
    type: 'text',
    operator: '%',
  },
  {
    key: 'idCard',
    label: 'field.idCard',
    type: 'text',
    operator: '%',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'positionId',
    label: 'field.position',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'position',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'managerId',
    label: 'field.manager',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
    clearable: true,
  },
  {
    key: 'regionId',
    label: 'field.region',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'region',
    selectionKey: 'id',
    selectionLabel: 'regionCode',
  },
  {
    key: 'workingScheduleId',
    label: 'field.workingSchedule',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'workingSchedule',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'isEnable',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.active', value: '1' },
      { text: 'field.inactive', value: '0' },
    ],
  },
  {
    key: 'createdAt',
    label: 'field.createdDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
]
