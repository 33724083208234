export default [
  {
    key: 'id',
    label: 'field.id',
    width: 2000,
    secondary: true,
  },
  {
    key: 'region',
    label: 'field.region',
    width: 2000,
  },
  {
    key: 'name',
    label: 'field.name',
    width: 6000,
  },
  {
    key: 'idCard',
    label: 'field.idCard',
    width: 2500,
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
    width: 2500,
  },
  {
    key: 'departmentName',
    label: 'field.department',
    localization: true,
    width: 7000,
  },
  {
    key: 'positionName',
    label: 'field.position',
    localization: true,
    width: 7000,
  },
  {
    key: 'manager',
    label: 'field.manager',
    width: 6000,
  },
  {
    key: 'managerIdCard',
    label: 'field.managerIdCard',
    width: 2500,
  },
  {
    key: 'workingScheduleName',
    label: 'field.workingSchedule',
    localization: true,
    width: 9000,
  },
  {
    key: 'dailySalesTargetAmount',
    label: 'field.dailySalesTargetAmount',
    width: 4000,
  },
  {
    key: 'weeklySalesTargetAmount',
    label: 'field.weeklySalesTargetAmount',
    width: 4000,
  },
  {
    key: 'monthlySalesTargetAmount',
    label: 'field.monthlySalesTargetAmount',
    width: 4000,
  },
  {
    key: 'yearlySalesTargetAmount',
    label: 'field.yearlySalesTargetAmount',
    width: 4000,
  },
  {
    key: 'dpVisible',
    label: 'field.dpVisible',
    width: 2000,
  },
  {
    key: 'isEnable',
    label: 'field.status',
    width: 2000,
  },
  {
    key: 'createdAt',
    label: 'field.createdDate',
    width: 4000,
  },
];